<template>
  <section v-loading="loading">
    <div class="exam-top">
      <h4>线上模考</h4>
      <el-button class="btn-create"
                 size="large"
                 icon="el-icon-circle-plus"
                 @click="$router.push({name: 'assignExam'})">
        创建考试
      </el-button>
    </div>
    <div class="search">
      <div class="left">
        <el-select class="search-select" v-model="provinceId" placeholder="省" @change="getCity" clearable>
          <el-option v-for="(item, index) in provinceList" :key="index" :label="item.name"
                     :value="item.id"></el-option>
        </el-select>
        <el-select class="search-select" v-model="cityId" placeholder="市" @change="getDistrict" clearable>
          <el-option v-for="(item, index) in cityList" :key="index" :label="item.name"
                     :value="item.id"></el-option>
        </el-select>
        <el-select class="search-select" v-model="districtId" placeholder="区" clearable>
          <el-option v-for="(item, index) in districtList" :key="index" :label="item.name"
                     :value="item.id"></el-option>
        </el-select>
        <el-select class="search-select" v-model="gradeId" placeholder="学段" clearable>
          <el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-input
            class="search-name"
            v-model="searchName"
            placeholder="输入学校名称搜索"
            @change="search"
            clearable></el-input>
      </div>
      <div class="btn">
        <el-button type="primary" @click="search">查询</el-button>
      </div>
    </div>

    <div class="school-list">
      <template v-for="item of list">
        <div class="school-list__item" :key="item.schoolId">
          <div class="school-list__item-left">
            <h6 class="school-list__item-left__name">{{ item.schoolName }}</h6>
            <p class="school-list__item-left__desc">
              <span style="margin-right: 10px;">{{ item.sectionName }}</span>
              {{ item.provinceName }}-{{ item.cityName }}-{{ item.countyName }}
            </p>
          </div>
          <p class="school-list__item-time">最近考试：{{ item.recentlyExamDate }}</p>
          <div class="school-list__item-jump"
               @click="getExamList(item)">
            <span>进入学校</span>
            <img class="school-list__item-jump-arrow" src="../../assets/icon-arrow-right-blue.png" alt="copy"/>
          </div>
        </div>
        <div class="school-list__item-line"/>
      </template>
    </div>

    <div class="pagination" v-if="totalCount>20">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="totalCount">
      </el-pagination>
    </div>

  </section>
</template>

<script>
// copy element-ui 的 backtop 代码
const cubic = value => Math.pow(value, 3);
const easeInOutCubic = value => value < 0.5
    ? cubic(value * 2) / 2
    : 1 - cubic((1 - value) * 2) / 2;

export default {
  name: 'checkExam',
  data() {
    return {
      loading: false,
      searchName: '',
      provinceList: [],
      provinceId: null,
      cityList: [],
      cityId: null,
      districtList: [],
      districtId: null,
      gradeList: [{
        id: 1,
        name: '初中'
      }, {
        id: 2,
        name: '高中'
      }],
      gradeId: null,
      list: [],
      pageIndex: 1,
      pageSize: 20,
      totalCount: 0
    }
  },
  created() {
    const superAdmin = sessionStorage.getItem('superAdmin')
    if (superAdmin === '2') {
      this.findSchoolListByTeacherId()
      this.getProvince()
    } else {
      this.$router.replace({name: 'examList'})
    }
  },
  activated() {
    const superAdmin = sessionStorage.getItem('superAdmin')
    if (superAdmin !== '2') {
      this.$router.replace({name: 'examList'})
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pageIndex = val
      this.findSchoolListByTeacherId()
    },
    handleCurrentChange(val) {
      this.pageIndex = val
      this.findSchoolListByTeacherId()
    },
    getExamList(item) {
      const {schoolId, schoolName, provinceId, section} = item
      this.$router.push({
        name: 'examList',
        query: {
          section,
          provinceId,
          schoolId,
          name: schoolName
        }
      })
    },
    // 获取省
    getProvince() {
      this.$axios({
        method: 'post',
        url: this.$urls.cityList,
        data: {
          gradeTypeId: 8
        }
      }).then((response) => {
        if (response.state === '11') {
          this.provinceList = response.data
        }
      })
    },
    // 获取市
    getCity() {
      this.cityList = []
      this.cityId = null
      this.districtId = null
      this.districtList = []
      this.$axios({
        method: 'post',
        url: this.$urls.cityGet,
        data: {
          pid: this.provinceId
        }
      }).then((response) => {
        if (response.state === '11') {
          this.cityList = response.data
        }
      })
    },
    // 获取区
    getDistrict() {
      this.districtId = null
      this.districtList = []
      this.$axios({
        method: 'post',
        url: this.$urls.cityGet,
        data: {
          pid: this.cityId
        }
      }).then((response) => {
        if (response.state === '11') {
          this.districtList = response.data
        }
      })
    },
    search() {
      this.pageIndex = 1
      this.findSchoolListByTeacherId()
    },
    findSchoolListByTeacherId() {
      this.loading = true
      const dataParam = {}
      if (this.searchName !== '') dataParam.schoolName = this.searchName
      if (this.provinceId) dataParam.province = this.provinceId
      if (this.cityId) dataParam.city = this.cityId
      if (this.districtId) dataParam.county = this.districtId
      if (this.gradeId) dataParam.section = this.gradeId
      const {pageIndex} = this
      const {pageSize} = this
      dataParam.pageIndex = pageIndex
      dataParam.pageSize = pageSize
      this.$axios({
        method: 'post',
        url: this.$urls.findSchoolListByTeacherId,
        headers: {
          'Content-Type': 'application/json'
        },
        data: dataParam
      }).then((response) => {
        this.loading = false
        if (response.state === '11') {
          const {info} = response
          this.list = info.result
          this.totalCount = info.totalCount
          this.scrollToTop()
          this.$store.commit('includeAdd', 'checkExam')
        }
      }).catch(() => {
        this.loading = false
      })
    },
    /**
     * ElementUI 的 backtop 代码
     */
    scrollToTop() {
      const el = document.querySelector('#app');
      const beginTime = Date.now();
      const beginValue = el.scrollTop;
      const rAF = window.requestAnimationFrame || (func => setTimeout(func, 16));
      const frameFunc = () => {
        const progress = (Date.now() - beginTime) / 500;
        if (progress < 1) {
          el.scrollTop = beginValue * (1 - easeInOutCubic(progress));
          rAF(frameFunc);
        } else {
          el.scrollTop = 0;
        }
      };
      rAF(frameFunc);
    }
  }
}
</script>

<style lang="scss">
.el-icon-circle-plus {
  color: #309AF2;
}
</style>
<style scoped lang="scss">
.exam-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 84px;
  color: #333333;
  font-size: 20px;
  padding: 0 28px;

  .btn-create {
    width: 130px;
    color: #309AF2;
    font-size: 15px;
    font-weight: normal;
    border: 1px solid #309AF2;
  }
}

.search {
  @include flex(space-between);
  height: 56px;
  background-color: #FFFFFF;
  padding: 0 28px;
  margin-bottom: 1px;

  .left {
    @include flex;

    .search-select {
      width: 140px;
      margin-right: 10px;
    }

    .search-name {
      width: 220px;
      font-size: 13px;
    }
  }

  .btn {
    padding-left: 28px;
    border-left: 1px solid #E5E5E5;

    button {
      width: 100px;
      height: 36px;
      font-size: 15px;
      border: none;
      background: linear-gradient(90deg, #6DBBFC 0%, #309AF2 100%);

      &:hover {
        background: linear-gradient(90deg, #309AF2 0%, #309AF2 100%);
      }
    }
  }
}

.school-list {
  min-height: calc(100vh - 174px);
  background-color: #FFFFFF;
  padding: 14px 28px;

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 28px;

    &:hover {
      box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.08);
      border-radius: 8px;
    }

    &-left {
      flex: 1;
      &__name {
        color: #333333;
        font-size: 17px;
      }

      &__desc {
        color: #999999;
        font-size: 15px;
        margin-top: 12px;
      }
    }

    &-time {
      color: #999999;
      font-size: 15px;
      width: 230px;
      text-align: left;
      margin-right: 100px;
    }

    &-jump {
      display: flex;
      align-items: center;
      width: 112px;
      height: 40px;
      color: #309AF2;
      font-size: 15px;
      border-radius: 4px;
      border: 1px solid #309AF2;
      cursor: pointer;
      padding: 0 14px;

      &:hover {
        background-color: #eaf5fe;
      }

      &-arrow {
        width: 14px;
        height: 14px;
        margin-left: 8px;
      }
    }

    &-line {
      height: 1px;
      background-color: #F1F1F1;
      margin: 0 28px;
    }
  }
}

.pagination {
  text-align: center;
  background-color: #FFFFFF;
  padding: 10px;
}
</style>
